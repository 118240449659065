import {
  FETCH_ORDER_LIST,
  FETCH_ORDER_LIST_FALIUR,
  FETCH_ORDER_LIST_SUCCESS,
  CREATE_NEW_ORDER,
} from "./Order.type";

const initialState = {
  loading: true,
  orderList: null,
  createOrder: null,
};

export default function orderReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ORDER_LIST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ORDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        orderList: action.payload,
      };
    case FETCH_ORDER_LIST_FALIUR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_NEW_ORDER:
      return {
        ...state,
        createOrder: action.payload,
      };
    default:
      return state;
  }
}
