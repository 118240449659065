import {
  FETCH_SHIPPING_BY_PIN,
  SET_PIN_NUMBER,
  REMOVE_PIN_NUMBER,
} from "./Shipping.type";

const initialState = {
  shippingDetails: null,
  pinNumber: null,
};

export default function shippingReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SHIPPING_BY_PIN:
      return {
        ...state,
        shippingDetails: action.payload,
      };
    case SET_PIN_NUMBER:
      return {
        ...state,
        pinNumber: action.payload,
      };
    case REMOVE_PIN_NUMBER:
      return {
        ...state,
        pinNumber: null,
      };
    default:
      return state;
  }
}
