export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const FETCH_USER_DETAILS = "FETCH_USER_DETAILS";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FALIUR = "UPDATE_USER_DETAILS_FALIUR";
export const CREATE_ADDRESS = "CREATE_ADDRESS";
export const REMOVE_ADDRESS = "REMOVE_ADDRESS";
export const ADD_DEVICE_ID = "ADD_DEVICE_ID";
export const SELECT_ADDRESS = "SELECT_ADDRESS";
