import { FETCH_OFFERS_LIST } from "./Offers.type";

const initialState = {
  offers: null,
};

export default function offersReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_OFFERS_LIST:
      return {
        ...state,
        offers: action.payload,
      };
    default:
      return state;
  }
}
