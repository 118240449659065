import {
  SET_CURRENT_USER,
  FETCH_USER_DETAILS,
  UPDATE_USER_DETAILS,
  UPDATE_USER_DETAILS_FALIUR,
  UPDATE_USER_DETAILS_SUCCESS,
  CREATE_ADDRESS,
  REMOVE_ADDRESS,
  ADD_DEVICE_ID,
  SELECT_ADDRESS,
} from "./User.type";

const initialState = {
  userByToken: null,
  userDetails: null,
  updateDetails: {
    loading: true,
  },
  deviceId: null,
  selectAddress: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        userByToken: action.payload,
      };
    case FETCH_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case UPDATE_USER_DETAILS:
      return {
        ...state,
        updateDetails: {
          ...state.updateDetails,
          loading: true,
        },
      };
    case UPDATE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        updateDetails: {
          ...state.updateDetails,
          loading: false,
        },
      };
    case UPDATE_USER_DETAILS_FALIUR:
      return {
        ...state,
        updateDetails: {
          ...state.updateDetails,
          loading: false,
        },
      };
    case CREATE_ADDRESS:
      return {
        ...state,
        createAddress: action.payload,
      };
    case REMOVE_ADDRESS:
      return {
        ...state,
        removeAddress: action.payload,
      };
    case ADD_DEVICE_ID:
      return {
        ...state,
        deviceId: action.payload,
      };
    case SELECT_ADDRESS:
      return {
        ...state,
        selectAddress: action.payload,
      };
    default:
      return state;
  }
}
