import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import { App } from "./App";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import { lazily } from "react-lazily";
const { App } = lazily(() => import("./App"));

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <React.Suspense fallback="Loading">
      <App />
    </React.Suspense>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);
