import { FETCH_LOCATION_BY_IP, SET_GEOLOCATION_DETAILS } from "./Location.type";

const initialState = {
  locationByIp: null,
  geoLocation: null,
};

export default function locationReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_LOCATION_BY_IP:
      return {
        ...state,
        locationByIp: action.payload,
      };
    case SET_GEOLOCATION_DETAILS:
      return {
        ...state,
        geoLocation: action.payload,
      };
    default:
      return state;
  }
}
