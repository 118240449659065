import {
  FETCH_BANNER,
  FETCH_BANNER_FALIUR,
  FETCH_BANNER_FIRST,
  FETCH_BANNER_FIRST_FALIUR,
  FETCH_BANNER_FIRST_SUCCESS,
  FETCH_BANNER_SECOND,
  FETCH_BANNER_SECOND_FALIUR,
  FETCH_BANNER_SECOND_SUCCESS,
  FETCH_BANNER_SUCCESS,
  FETCH_BANNER_THIRD,
  FETCH_BANNER_THIRD_FALIUR,
  FETCH_BANNER_THIRD_SUCCESS,
} from "./Banner.type";

const initalState = {
  category: {
    banner: null,
    loading: true,
  },
  category1: {
    banner: null,
    loading: true,
  },
  category2: {
    banner: null,
    loading: true,
  },
  category3: {
    banner: null,
    loading: true,
  },
};

export default function bannerReducer(state = initalState, action) {
  switch (action.type) {
    case FETCH_BANNER:
      return {
        ...state,
        category: {
          ...state.category,
          loading: true,
        },
      };
    case FETCH_BANNER_FALIUR:
      return {
        ...state,
        category: {
          ...state.category,
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_BANNER_SUCCESS:
      return {
        ...state,
        category: {
          ...state.category,
          loading: false,
          banner: action.payload,
        },
      };
    case FETCH_BANNER_FIRST:
      return {
        ...state,
        category1: {
          ...state.category1,
          loading: true,
        },
      };
    case FETCH_BANNER_FIRST_FALIUR:
      return {
        ...state,
        category1: {
          ...state.category1,
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_BANNER_FIRST_SUCCESS:
      return {
        ...state,
        category1: {
          ...state.category1,
          loading: false,
          banner: action.payload,
        },
      };
    case FETCH_BANNER_SECOND:
      return {
        ...state,
        category2: {
          ...state.category2,
          loading: true,
        },
      };
    case FETCH_BANNER_SECOND_FALIUR:
      return {
        ...state,
        category2: {
          ...state.category2,
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_BANNER_SECOND_SUCCESS:
      return {
        ...state,
        category2: {
          ...state.category2,
          loading: false,
          banner: action.payload,
        },
      };
    case FETCH_BANNER_THIRD:
      return {
        ...state,
        category3: {
          ...state.category3,
          loading: true,
        },
      };
    case FETCH_BANNER_THIRD_FALIUR:
      return {
        ...state,
        category3: {
          ...state.category3,
          loading: false,
          error: action.payload,
        },
      };

    case FETCH_BANNER_THIRD_SUCCESS:
      return {
        ...state,
        category3: {
          ...state.category3,
          loading: false,
          banner: action.payload,
        },
      };
    default:
      return state;
  }
}
