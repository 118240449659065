import {
  CART_LIST,
  CART_LIST_FALIUR,
  CART_LIST_SUCCESS,
  REMOVE_FROM_CART,
} from "./Cart.type";

const initialState = {
  loading: true,
  cartList: null,
};

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case CART_LIST:
      return {
        ...state,
        loading: true,
      };
    case CART_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        cartList: action.payload,
      };
    case CART_LIST_FALIUR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case REMOVE_FROM_CART:
      return {
        ...state,
        removeItem: action.payload,
      };
    default:
      return state;
  }
}
