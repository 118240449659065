export const FETCH_BANNER = "FETCH_BANNER";
export const FETCH_BANNER_SUCCESS = "FETCH_BANNER_SUCCESS";
export const FETCH_BANNER_FALIUR = "FETCH_BANNER_FALIUR";
export const FETCH_BANNER_FIRST = "FETCH_BANNER_FIRST";
export const FETCH_BANNER_FIRST_SUCCESS = "FETCH_BANNER_FIRST_SUCCESS";
export const FETCH_BANNER_FIRST_FALIUR = "FETCH_BANNER_FIRST_FALIUR";
export const FETCH_BANNER_SECOND = "FETCH_BANNER_SECOND";
export const FETCH_BANNER_SECOND_SUCCESS = "FETCH_BANNER_SECOND_SUCCESS";
export const FETCH_BANNER_SECOND_FALIUR = "FETCH_BANNER_SECOND_FALIUR";
export const FETCH_BANNER_THIRD = "FETCH_BANNER_THIRD";
export const FETCH_BANNER_THIRD_SUCCESS = "FETCH_BANNER_THIRD_SUCCESS";
export const FETCH_BANNER_THIRD_FALIUR = "FETCH_BANNER_THIRD_FALIUR";
