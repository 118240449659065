import {
  FETCH_COUNTRY_LIST,
  FETCH_LOGO_CAPTION_LIST,
  SET_SELECTED_COUNTRY,
  SET_SELECTED_LOGO_CAP,
} from "./Country.type";

const initialState = {
  countryList: null,
  logoCapList: null,
  selectedCountry: null,
  selectedLogoCap: null,
};

export default function CountryReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_COUNTRY_LIST:
      return { ...state, countryList: action.payload };
    case FETCH_LOGO_CAPTION_LIST:
      return { ...state, logoCapList: action.payload };
    case SET_SELECTED_COUNTRY:
      return {
        ...state,
        selectedCountry: action.payload,
      };
    case SET_SELECTED_LOGO_CAP:
      return {
        ...state,
        selectedLogoCap: action.payload,
      };
    default:
      return state;
  }
}
