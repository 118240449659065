import {
  FETCH_PAGE_INFO,
  FETCH_STORE_INFO,
  FETCH_SOCIAL_INFO,
} from "./PageInfo.type";

const initialState = {
  page: null,
  storeInfo: null,
  socialMedia: null,
};

export default function pageInfoReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PAGE_INFO:
      return {
        ...state,
        page: action.payload,
      };
    case FETCH_STORE_INFO:
      return {
        ...state,
        storeInfo: action.payload,
      };
    case FETCH_SOCIAL_INFO:
      return {
        ...state,
        socialMedia: action.payload,
      };
    default:
      return state;
  }
}
