export const FETCH_CAT_PRODUCT = "FETCH_CAT_PRODUCT";
export const FETCH_CAT_PRODUCT_SUCCESS = "FETCH_CAT_PRODUCT_SUCCESS";
export const FETCH_CAT_PRODUCT_FALIUR = "FETCH_CAT_PRODUCT_FALIUR";
export const FETCH_CAT_PRODUCT_SECOND = "FETCH_CAT_PRODUCT_SECOND";
export const FETCH_CAT_PRODUCT_SECOND_SUCCESS =
  "FETCH_CAT_PRODUCT_SECOND_SUCCESS";
export const FETCH_CAT_PRODUCT_SECOND_FALIUR =
  "FETCH_CAT_PRODUCT_SECOND_FALIUR";
export const FETCH_CAT_PRODUCT_THIRD = "FETCH_CAT_PRODUCT_THIRD";
export const FETCH_CAT_PRODUCT_THIRD_SUCCESS =
  "FETCH_CAT_PRODUCT_THIRD_SUCCESS";
export const FETCH_CAT_PRODUCT_THIRD_FALIUR = "FETCH_CAT_PRODUCT_THIRD_FALIUR";
export const FETCH_CAT_PRODUCT_FOURTH = "FETCH_CAT_PRODUCT_FOURTH";
export const FETCH_CAT_PRODUCT_FOURTH_SUCCESS =
  "FETCH_CAT_PRODUCT_FOURTH_SUCCESS";
export const FETCH_CAT_PRODUCT_FOURTH_FALIUR =
  "FETCH_CAT_PRODUCT_FOURTH_FALIUR";
export const FETCH_COMBO_PRODUCT = "FETCH_COMBO_PRODUCT";
export const FETCH_COMBO_PRODUCT_SUCCESS = "FETCH_COMBO_PRODUCT_SUCCESS";
export const FETCH_COMBO_PRODUCT_FALIUR = "FETCH_COMBO_PRODUCT_FALIUR";
export const PRODUCT_BY_ID = "PRODUCT_BY_ID";
export const FETCH_SIMILER_PRODUCT = "FETCH_SIMILER_PRODUCT";
export const FETCH_SIMILER_PRODUCT_SUCCESS = "FETCH_SIMILER_PRODUCT_SUCCESS";
export const FETCH_SIMILER_PRODUCT_FALIUR = "FETCH_SIMILER_PRODUCT_FALIUR";
export const SAVE_FIRST_CAT_PRODUCT = "SAVE_FIRST_CAT_PRODUCT";
export const SAVE_SECOND_CAT_PRODUCT = "SAVE_SECOND_CAT_PRODUCT";
export const SAVE_THIRD_CAT_PRODUCT = "SAVE_THIRD_CAT_PRODUCT";
export const SAVE_FOURTH_CAT_PRODUCT = "SAVE_FOURTH_CAT_PRODUCT";
export const FETCH_FEATURE_PRODUCT = "FETCH_FEATURE_PRODUCT";
export const FETCH_FEATURE_PRODUCT_SUCCESS = "FETCH_FEATURE_PRODUCT_SUCCESS";
export const FETCH_FEATURE_PRODUCT_FALIUR = "FETCH_FEATURE_PRODUCT_FALIUR";
export const FETCH_FEATURE_PRODUCT_BY_CAT = "FETCH_FEATURE_PRODUCT_BY_CAT";
export const FETCH_FEATURE_PRODUCT_BY_CAT_SUCCESS =
  "FETCH_FEATURE_PRODUCT_BY_CAT_SUCCESS";
export const FETCH_FEATURE_PRODUCT_BY_CAT_FALIUR =
  "FETCH_FEATURE_PRODUCT_BY_CAT_FALIUR";
