import { combineReducers } from "redux";

import bannerReducer from "./Banner/Banner.reducer";
import productReducer from "./Product/product.reducer";
import locationReducer from "./location/Location.reducer";
import countryReducer from "./Country/Country.reducer";
import userReducer from "./User/User.reducer";
import cartReducer from "./Cart/Cart.reducer";
import WishListReducer from "./WishList/WishList.reducer";
import shippingReducer from "./Shipping/Shipping.reducer";
import orderReducer from "./Order/Order.reducer";
import offersReducer from "./Offers/Offers.reducer";
import pageInfoReducer from "./PageInfo/PageInfo.reducer";

export default combineReducers({
  banner: bannerReducer,
  product: productReducer,
  location: locationReducer,
  country: countryReducer,
  user: userReducer,
  cart: cartReducer,
  wishList: WishListReducer,
  shipping: shippingReducer,
  order: orderReducer,
  offer: offersReducer,
  pageInfo: pageInfoReducer,
});
