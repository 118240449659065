import {
  FETCH_CAT_PRODUCT,
  FETCH_CAT_PRODUCT_FALIUR,
  FETCH_CAT_PRODUCT_FOURTH,
  FETCH_CAT_PRODUCT_FOURTH_FALIUR,
  FETCH_CAT_PRODUCT_FOURTH_SUCCESS,
  FETCH_CAT_PRODUCT_SECOND,
  FETCH_CAT_PRODUCT_SECOND_FALIUR,
  FETCH_CAT_PRODUCT_SECOND_SUCCESS,
  FETCH_CAT_PRODUCT_SUCCESS,
  FETCH_CAT_PRODUCT_THIRD,
  FETCH_CAT_PRODUCT_THIRD_FALIUR,
  FETCH_CAT_PRODUCT_THIRD_SUCCESS,
  FETCH_COMBO_PRODUCT,
  FETCH_COMBO_PRODUCT_FALIUR,
  FETCH_COMBO_PRODUCT_SUCCESS,
  PRODUCT_BY_ID,
  FETCH_SIMILER_PRODUCT,
  FETCH_SIMILER_PRODUCT_FALIUR,
  FETCH_SIMILER_PRODUCT_SUCCESS,
  SAVE_FIRST_CAT_PRODUCT,
  SAVE_FOURTH_CAT_PRODUCT,
  SAVE_SECOND_CAT_PRODUCT,
  SAVE_THIRD_CAT_PRODUCT,
  FETCH_FEATURE_PRODUCT_BY_CAT,
  FETCH_FEATURE_PRODUCT_BY_CAT_FALIUR,
  FETCH_FEATURE_PRODUCT_BY_CAT_SUCCESS,
  FETCH_FEATURE_PRODUCT,
  FETCH_FEATURE_PRODUCT_FALIUR,
  FETCH_FEATURE_PRODUCT_SUCCESS,
} from "./product.type";

const initalState = {
  catProduct: {
    loading: true,
    product: null,
  },
  catProduct2: {
    loading: true,
    product: null,
  },
  catProduct3: {
    loading: true,
    product: null,
  },
  catProduct4: {
    loading: true,
    product: null,
  },
  comboProduct: {
    loading: true,
    product: null,
  },
  productById: null,
  similerProduct: {
    loading: true,
    product: null,
  },
  featureProduct: {
    loading: true,
    product: null,
  },
  featureProductByCat: {
    loading: true,
    product: null,
  },
};

export default function productReducer(state = initalState, action) {
  switch (action.type) {
    case FETCH_CAT_PRODUCT:
      return {
        ...state,
        catProduct: {
          loading: true,
        },
      };
    case FETCH_CAT_PRODUCT_FALIUR:
      return {
        ...state,
        catProduct: {
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_CAT_PRODUCT_SUCCESS:
      return {
        ...state,
        catProduct: {
          loading: false,
          product: action.payload,
        },
      };
    case FETCH_CAT_PRODUCT_SECOND:
      return {
        ...state,
        catProduct2: {
          loading: true,
        },
      };
    case FETCH_CAT_PRODUCT_SECOND_FALIUR:
      return {
        ...state,
        catProduct2: {
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_CAT_PRODUCT_SECOND_SUCCESS:
      return {
        ...state,
        catProduct2: {
          loading: false,
          product: action.payload,
        },
      };
    case FETCH_CAT_PRODUCT_THIRD:
      return {
        ...state,
        catProduct3: {
          loading: true,
        },
      };
    case FETCH_CAT_PRODUCT_THIRD_FALIUR:
      return {
        ...state,
        catProduct3: {
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_CAT_PRODUCT_THIRD_SUCCESS:
      return {
        ...state,
        catProduct3: {
          loading: false,
          product: action.payload,
        },
      };
    case FETCH_CAT_PRODUCT_FOURTH:
      return {
        ...state,
        catProduct4: {
          loading: true,
        },
      };
    case FETCH_CAT_PRODUCT_FOURTH_FALIUR:
      return {
        ...state,
        catProduct4: {
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_CAT_PRODUCT_FOURTH_SUCCESS:
      return {
        ...state,
        catProduct4: {
          loading: false,
          product: action.payload,
        },
      };
    case FETCH_COMBO_PRODUCT:
      return {
        ...state,
        comboProduct: {
          loading: true,
        },
      };
    case FETCH_COMBO_PRODUCT_FALIUR:
      return {
        ...state,
        comboProduct: {
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_COMBO_PRODUCT_SUCCESS:
      return {
        ...state,
        comboProduct: {
          loading: false,
          product: action.payload,
        },
      };
    case PRODUCT_BY_ID:
      return {
        ...state,
        productById: action.payload,
      };
    case FETCH_SIMILER_PRODUCT:
      return {
        ...state,
        similerProduct: {
          loading: true,
        },
      };
    case FETCH_SIMILER_PRODUCT_FALIUR:
      return {
        ...state,
        similerProduct: {
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_SIMILER_PRODUCT_SUCCESS:
      return {
        ...state,
        similerProduct: {
          loading: false,
          product: action.payload,
        },
      };
    case SAVE_FIRST_CAT_PRODUCT:
      return {
        ...state,
        catProduct: {
          ...state.catProduct,
          product: {
            ...state.catProduct.product,
            response: state.catProduct.product?.response?.concat(
              action.payload
            ),
          },
        },
      };
    case SAVE_SECOND_CAT_PRODUCT:
      console.log(state?.catProduct2);
      return {
        ...state,
        catProduct2: {
          ...state.catProduct2,
          product: {
            ...state.catProduct2.product,
            response: state?.catProduct2?.product?.response?.concat(
              action.payload
            ),
          },
        },
      };
    case SAVE_THIRD_CAT_PRODUCT:
      return {
        ...state,
        catProduct3: {
          ...state.catProduct3,
          product: {
            ...state.catProduct3.product,
            response: state.catProduct3.product?.response?.concat(
              action.payload
            ),
          },
        },
      };
    case SAVE_FOURTH_CAT_PRODUCT:
      return {
        ...state,
        catProduct4: {
          ...state.catProduct4,
          product: {
            ...state.catProduct4.product,
            response: state.catProduct4.product?.response?.concat(
              action.payload
            ),
          },
        },
      };
    case FETCH_FEATURE_PRODUCT:
      return {
        ...state,
        featureProduct: {
          loading: true,
        },
      };
    case FETCH_FEATURE_PRODUCT_FALIUR:
      return {
        ...state,
        featureProduct: {
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_FEATURE_PRODUCT_SUCCESS:
      return {
        ...state,
        featureProduct: {
          loading: false,
          product: action.payload,
        },
      };
    case FETCH_FEATURE_PRODUCT_BY_CAT:
      return {
        ...state,
        featureProductByCat: {
          loading: true,
        },
      };
    case FETCH_FEATURE_PRODUCT_BY_CAT_FALIUR:
      return {
        ...state,
        featureProductByCat: {
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_FEATURE_PRODUCT_BY_CAT_SUCCESS:
      return {
        ...state,
        featureProductByCat: {
          loading: false,
          product: action.payload,
        },
      };
    default:
      return state;
  }
}
