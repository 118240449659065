import {
  FETCH_WISH_LIST,
  FETCH_WISH_LIST_FAILURE,
  FETCH_WISH_LIST_SUCCESS,
} from "./WishList.type";

const initialState = {
  loading: true,
  list: null,
};

export default function WishListReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_WISH_LIST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_WISH_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_WISH_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
